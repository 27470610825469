import React from 'react';
import { useParams } from "react-router-dom";

import { Box, Breadcrumbs, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import DetailView from './view.js'
import LogsList from './logs-list.js'

const useStyles = makeStyles((theme) => ({
  logs: {
    padding: 15,
    width: '100%',
    '& .link': {
      cursor: 'pointer'
    }
  }
}));

const LogsPage = (props) => {
  const classes = useStyles();
  const params = useParams();

  return (
    <Box className={classes.logs}>
      <Typography variant="h1" component="h3" gutterBottom>Token Detail</Typography>
      <Breadcrumbs>
        <Link className="link" href="/">Manage Tokens</Link>
        <Typography>{params.token}</Typography>
      </Breadcrumbs>
      <DetailView />      
      <LogsList />
    </Box>
  );
}

export default LogsPage
