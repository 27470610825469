import React, { useContext } from 'react'

const ResponsesManagementContext = React.createContext({
    currentState: 'unloaded',
    responses: [],
    focusedResponse: null,
    error: null,
    setError: (error) => { },
    transition: (actions) => { }
})

export const useResponsesContext = () => useContext(ResponsesManagementContext)

export default ResponsesManagementContext