import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { add, format } from 'date-fns';
import { Formik } from 'formik';
import { useHistory } from "react-router-dom";
import {
  TextField,
  TextareaAutosize,
  Button,
  Box,
  Card,
  CircularProgress,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  FormHelperText,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { getResponse, createResponse, updateResponse } from '../../utils/api.js'
import { getSelectItems } from '../../utils/responses.js'
import validationSchema from '../responses/validation-schema';
import { useResponsesContext } from '../../context/responses-management-context.js'

const useStyles = makeStyles((theme) => ({
  response: {
    padding: 15,
    width: '100%',
    '& .link': {
      cursor: 'pointer'
    }
  },
  form: {},
  label: {
    background: 'white'
  }
}))



const ResponseForm = (props) => {
  const classes = useStyles()
  const history = useHistory()
  const [disableButton, setDisableButton] = useState(false)
  const [responseEndPoints, setResponseEndPoints] = useState([])
  const [initialFormValues, setInitialFormValues] = useState([])
  const { currentState, transition, focusedResponse } = useResponsesContext();
  const responsesContext = useResponsesContext()
  const desiredFormat = 'yyyy-MM-dd'

  const [inFlight, setInFlight] = useState(false)
  const [data, setData] = useState()

  useEffect(() => {
    const doWork = async () => {
      try {
        setDisableButton(true)
        const results = await getSelectItems()
        setResponseEndPoints(results)
        if (currentState === 'edit' && focusedResponse) {
          const editResponseData = await getResponse(focusedResponse)

          const initFV = {
            keyvalue: editResponseData.id.substring(editResponseData.id.lastIndexOf(':') + 1),
            bodyDoc: editResponseData.body,
            timestamp: format(add(new Date(), { days: 30 }), desiredFormat),
            status: editResponseData.status,
            endpoint: editResponseData.id.substring(0, editResponseData.id.lastIndexOf(':') + 1),
          }
          setInitialFormValues(initFV)
          setData(results)
        } else {
          const initFV = {
            keyvalue: '',
            bodyDoc: '',
            timestamp: format(add(new Date(), { days: 30 }), desiredFormat),
            status: "200",
            endpoint: '',
          }
          setInitialFormValues(initFV)
          setData(results)
        }
        setInFlight(false)
        setDisableButton(false)
      } catch (err) {
        setInFlight(false)
        setDisableButton(false)
        setData({ error: 'trouble loading' })
      }
    }

    if (inFlight === false && !data) {
      setInFlight(true)
      doWork()
    }
  }, [inFlight, setInFlight, setData, data, initialFormValues, currentState, focusedResponse])

  if (inFlight === true) {
    return (
      <Card variant="outlined" className={classes.detailView}>
        <CircularProgress />
      </Card>
    )
  }

  if (!data) return null

  const handleSave = async (data) => {
    try {
      const newId = data.endpoint + data.keyvalue
      const payload = {
        id: newId,
        body: data.bodyDoc,
        headers: '', //until field is added
        status: data.status
      }
      setDisableButton(true)
      // await getResponse(data)
      if (responsesContext.currentState === 'edit') {
        //update
        await updateResponse(focusedResponse, payload)
        transition('SAVE')
      } else {
        const result = await createResponse(payload)
        if (result.created && result.created.id === newId) {
          history.push('/api-responses')
        }
        //confirm result and redirect to response form
      }

      setDisableButton(false)
      // history.push('/')
    } catch (err) {
      setDisableButton(false)
    }
  }

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={initialFormValues}
      enableReinitialize={true}
      onSubmit={handleSave}
      validationSchema={validationSchema}
    >
      {(props) => {
        const {
          errors,
          handleChange,
          handleSubmit,
          values,
        } = props

        return (
          <Box className={classes.response}>
            <form onSubmit={handleSubmit} className={classes.form}>
              <Box display='flex' flexDirection='column' width='50%'>
                <Box display="flex" flexDirection='column' width="100%" mb="1em">
                  <FormControl
                    margin='dense'
                    variant='outlined'
                  >
                    <InputLabel id='endpoint' className={classes.label}>
                      Endpoint
                    </InputLabel>
                    <Select
                      labelId='endpoint'
                      onChange={handleChange}
                      value={values.endpoint || ''}
                      name='endpoint'
                    >
                      {responseEndPoints.map((item) => {
                        return item
                      })}
                    </Select>
                  </FormControl>
                  {errors.endpoint && (
                    <FormHelperText error={true}>{errors.endpoint}</FormHelperText>
                  )}
                </Box>
                <Box display="flex" flexDirection='column' width="100%" mb="1em">
                  <TextField
                    label='KeyValue'
                    onChange={handleChange}
                    value={values.keyvalue}
                    name='keyvalue'
                    type='text'
                    variant='outlined'
                    margin='dense'
                  />
                  {errors.keyvalue && (
                    <FormHelperText error={true}>{errors.keyvalue}</FormHelperText>
                  )}
                </Box>
                <Box display="flex" flexDirection='column' width="100%" mb="1em">
                  <FormControl
                    margin='dense'
                    variant='outlined'
                  >
                    <InputLabel id='status' className={classes.label}>
                      Status Code
                    </InputLabel>
                    <Select
                      labelId='status'
                      onChange={handleChange}
                      value={values.status || ''}
                      name='status'
                    >
                      <MenuItem value='200'>200</MenuItem>
                      <MenuItem value='401'>401</MenuItem>
                      <MenuItem value='404'>404</MenuItem>
                      <MenuItem value='500'>500</MenuItem>
                    </Select>
                  </FormControl>
                  {errors.status && (
                    <FormHelperText error={true}>{errors.status}</FormHelperText>
                  )}
                </Box>
                <Box display="flex" flexDirection='column' width="100%" mb="4em">
                  <Typography>Body:</Typography>
                  <TextareaAutosize
                    label='Body'
                    onChange={handleChange}
                    value={values.bodyDoc}
                    name='bodyDoc'
                    rowsMin={4}
                    rowsMax={6}
                    type='text'
                    variant='outlined'
                    margin='dense'
                  />
                  {errors.bodyDoc && (
                    <FormHelperText error={true}>{errors.bodyDoc}</FormHelperText>
                  )}
                </Box>
                <Box mb="1em">
                  <TextField
                    id="timestamp"
                    label="timestamp"
                    name="timestamp"
                    type="date"
                    value={values.timestamp}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Box>
                <Box display='flex'>
                  <Button
                    variant='contained'
                    color='primary'
                    type='submit'
                    onClick={() => {
                      handleSubmit()
                    }}
                    disabled={disableButton}
                    className='submit-button'
                  >
                    {(responsesContext.currentState === "edit") ? 'Update' : 'Save'}
                  </Button>
                  <Button
                    variant='outlined'
                    color='primary'
                    disabled={disableButton}
                    onClick={() => {
                      if (responsesContext.currentState === 'edit') {
                        transition('CANCEL')
                      } else {
                        history.push('/api-responses')

                      }
                    }}
                    className='cancel-button'
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>
        )
      }}
    </Formik>
  )
}

ResponseForm.propTypes = {
  errors: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.array.isRequired
}
export default ResponseForm