import qs from 'qs'
import _ from 'lodash'
import axios from 'axios'
import config from '../config/index.js'

const gitlabUrl = 'https://gitlab.openretailing.org';
const { redirectUri: redirect_uri, apiEndpoint } = config;
const client_id = 'e5c2b729788276b65f2026f3bf57538c0568cda0483f1dd13337b2946cd62570';
const client_secret = '4f8d10bd0e833a0adab4eeb63ad60bc01355e166af48fd981ce7e4cf00e34bfd';

const authError = error => console.log('Auth Error: ', error);
const tokensError = error => console.log('Tokens Error: ', error);
const responsesError = error => console.log('Responses Error: ', error);

const loadOAuthToken = () => window.localStorage.getItem('token');
const wipeOAuthToken = () => window.localStorage.removeItem('token');
const saveOAuthToken = (accessToken) => window.localStorage.setItem('token', accessToken);

const loadHeaders = () => ({ Authorization: `Bearer ${loadOAuthToken()}`, access_token: loadOAuthToken()});

const hasOAuthCode = (args) => _.get(args, 'code', false) !== false;
const hasOAuthToken = () => _.isNull(loadOAuthToken()) === false;

const api = () => axios.create({ 
  baseURL: apiEndpoint,
  headers: loadHeaders()
});

const gitlab = () => axios.create({ 
  baseURL: gitlabUrl,
  headers: loadHeaders()
});

export const oauthRedirect = async () => {
  try {
    wipeOAuthToken();

    const params = qs.stringify({ 
      client_id,
      redirect_uri,
      state: 'portal',
      response_type: 'code',
      scopes: 'api+read_user+openid+profile+email'
    });

    window.location.assign(`${gitlabUrl}/oauth/authorize?${params}`);

    return { type: 'SUCCESS' }
  } catch (err) {
    return { type: 'ERROR', payload: 'problem requesting auth code.' }
  }
};

export const oauthFetchToken = async (args) => {
  const state = _.get( args, 'state', 'portal');
  
  try{
    if (hasOAuthToken()) return { 
      type: 'SUCCESS', 
      payload: {
        state,
        access_token: loadOAuthToken()
      }
    };

    if (hasOAuthCode(args)) {
      const response = await api().post( '/auth/provider', {
        state,
        client_id,
        redirect_uri,
        client_secret,
        code: _.get( args, 'code' ),
        grant_type: 'authorization_code'
      });

      saveOAuthToken(_.get( response, 'data.access_token' ));

      return {
        type: 'SUCCESS',
        payload: { 
          state,
          access_token: _.get( response, 'data.access_token' )
        }
      }
    }

    return { 
      type: 'ERROR',
      payload: 'no token or code.' 
    };
  } catch (err) {
    wipeOAuthToken()
    return { type: 'ERROR', payload: err.message }
  }
};

export const oauthFetchUser = async () => {
  if (hasOAuthToken()) {
    const response = await gitlab().get('/api/v4/user', { headers: { Authorization: `Bearer ${loadOAuthToken()}`}}).catch(authError);

    return { type: 'SUCCESS', payload: _.get( response, 'data' )};
  }

  return { type: 'ERROR', payload: 'no token or code.' };
};

export const getToken = async (args) => {
  const response = await api().get(`/tokens/${_.get(args, 'token')}`).catch(tokensError);

  return _.get(response, 'data', {});
};

export const getTokens = async () => {
  const response = await api().get('/tokens').catch(tokensError);

  return _.get(response, 'data.records', []);
};

export const createToken = async (args) => {
  const response = await api().post('/tokens', args).catch(tokensError);

  return _.get( response, 'data.created', false);
};

export const updateToken = async (token, args) => {
  const response = await api().post(`/tokens/${token}`, args).catch(tokensError);

  return response;
};

export const disableToken = async (args) => {
  const response = await api().post(`/tokens/disable/${_.get(args, 'token')}`).catch(tokensError);

  return response;
};

export const deleteToken = async (args) => {
  const response = await api().delete(`/tokens/${_.get(args, 'token')}`).catch(tokensError);

  return response;
};

export const getLogs = async (args) => {
  const response = await api().get(`/tokens/${_.get(args, 'token')}/logs`).catch(tokensError);

  return _.get( response, 'data', []);
};

export const getResponses = async () => {
  const response = await api().get('/responses').catch(responsesError);
  const records = _.get( response, 'data.records', [])
  return _.isArray(records) ? records : [];
};

export const getResponse = async (id) => {
  const encodedId = encodeURIComponent(id);
  const response = await api().get(`/responses/${encodedId}`).catch(responsesError);
  
  return _.get( response, 'data', {});
};

export const createResponse = async (payload) => {
  const response = await api().post('/responses', payload).catch(responsesError);
  
  return _.get( response, 'data', {});
};

export const updateResponse = async (id, payload) => {
  const encodedId = encodeURIComponent(id);
  const response = await api().post(`/responses/${encodedId}`, payload).catch(responsesError);
  
  return _.get( response, 'data', {});
};

export const deleteResponse = async (id) => {
  const encodedId = encodeURIComponent(id);
  const response = await api().delete(`/responses/${encodedId}`).catch(responsesError);
  
  return _.get( response, 'data', {});
};
