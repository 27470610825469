export default [
  {
    name: 'method',
    label: 'Method',
    options: {
      filter: false,
      sort: false
    }
  },
  {
    name: 'request',
    label: 'Resource',
    options: {
      filter: false,
      sort: false
    }
  },
  {
    name: 'token',
    label: 'Token',
    options: {
      filter: false,
      sort: false
    }
  },
  {
    name: 'createdAt',
    label: 'Created',
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: '',
    label: 'Actions',
    options: {
      filter: false,
      sort: false
    }
  }
]