import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import { parseISO, format } from 'date-fns';

import { Box, Card, CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { getToken } from '../../utils/api.js'

const useStyles = makeStyles((theme) => ({
  detailView: {
    marginTop: 25,
    padding: 15,
    '& .label': {
      width: '15%',
      marginRight: 25,
    }
  }
}));

const desiredFormat = 'MM-dd-yyyy'

const TokenView = (props) => {
  const classes = useStyles()
  const params = useParams()
  const [inFlight, setInFlight] = useState(false)
  const [data, setData] = useState()

  useEffect(() => {
    const doWork = async () => {
      try {
        const results = await getToken(params)
        setData(results)
        setInFlight(false)
      } catch (err) {
        setInFlight(false)
        setData({ error: 'trouble loading' })
      }
    }

    if (inFlight === false && !data) {
      setInFlight(true)
      doWork()
    }
  }, [inFlight, params, setInFlight, setData, data])

  if (inFlight === true) {
    return (
      <Card variant="outlined" className={classes.detailView}>
        <CircularProgress />
      </Card>
    )
  }

  if (!data) return null

  const emails = data.emails.map((email, index) => (
    <Typography key={`email-item-${index}`}>{email}</Typography>
  ))

  return (
    <Card variant="outlined" className={classes.detailView}>
      <Box display="flex" mb="0.5em">
        <Typography className="label">Token</Typography>
        <Typography>{data.token}</Typography>
      </Box>
      <Box display="flex" mb="0.5em">
        <Typography className="label">Created</Typography>
        <Typography>{format(parseISO(data.createdAt), desiredFormat)}</Typography>
      </Box>
      <Box display="flex" mb="0.5em">
        <Typography className="label">Revoked</Typography>
        <Typography>{data.revokedAt && format(parseISO(data.revokedAt), desiredFormat)}</Typography>
      </Box>
      <Box display="flex" mb="0.5em">
        <Typography className="label">Emails</Typography>
        <Box display="flex" flexDirection="column">
          {emails}
        </Box>
      </Box>
    </Card>
  )
}

export default TokenView
