import * as Yup from 'yup';

const ManageMenuValidationSchema = Yup.object().shape({
  name: Yup.string().required('A label is required.').min(1),
  tokenType: Yup.string().required('You must select a Token Type.'),
  apiAccess: Yup.array().required('You must have at least one API.').min(1),
  emails: Yup.string().required('You must have at least one email.').min(1)
});

export default ManageMenuValidationSchema;
