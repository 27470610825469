import React, { useEffect, useRef } from 'react';

import { Box, Card, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useTokenContext } from '../../context/token-management-context';

const useStyles = makeStyles((theme) => ({
  filter: {
    padding: 15,
    '& div:nth-child(2)': {
      borderRight: '0px'
    },
    '& .title': {
      height: 45,
      marginRight: '1em',
      display: 'flex',
      alignItems: 'center'
    },
    '& .item': {
      height: 45,
      width: 200,
      border: '1px solid #000',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer'
    },
    '& .item:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: 'white'
    },
    '& .selected': {
      backgroundColor: theme.palette.primary.main,
      color: 'white'
    }
  }
}));

const FilterCard = () => {
  const classes = useStyles();
  const { currentFilter, transition } = useTokenContext();
  let allSelected = useRef(currentFilter === 'all' ? 'selected' : '');
  let integrationSelected = useRef(currentFilter === 'integration' ? 'selected' : '');
  let preCertificationSelected = useRef(currentFilter === 'precertification' ? 'selected' : '');
  let certificationSelected = useRef(currentFilter === 'certification' ? 'selected' : '');

  useEffect(() => {
    allSelected.current = currentFilter === 'all' ? 'selected' : ''
    integrationSelected.current = currentFilter === 'integration' ? 'selected' : ''
    preCertificationSelected.current = currentFilter === 'precertification' ? 'selected' : ''
    certificationSelected.current = currentFilter === 'certification' ? 'selected' : ''
  }, [currentFilter, allSelected, integrationSelected, preCertificationSelected, certificationSelected]);

  const handleClick = (args) => {
    allSelected.current = args === 'all' ? 'selected' : ''
    integrationSelected.current = args === 'integration' ? 'selected' : ''
    preCertificationSelected.current = args === 'precertification' ? 'selected' : ''
    certificationSelected.current = args === 'certification' ? 'selected' : ''
    transition({ cmd: 'FILTER', prevState: 'displayed', filter: args })
  }

  return (
    <Card variant="outlined" className={classes.filter}>
      <CardContent>
        <Typography component='div' className='title'>
        </Typography>
        <Box className='choices' justifyContent='space-between'>
          <Box display='flex'>
            <Typography component='div' className='title'>
              Filter by Token Type:
            </Typography>
            <Box
              className={`item ${allSelected.current}`}
              onClick={() => handleClick('all')}
            >
              <Typography component='span'>All</Typography>
            </Box>
            <Box
              className={`item ${integrationSelected.current}`}
              onClick={() => handleClick('integration')}
            >
              <Typography component='span'>Integration</Typography>
            </Box>
            <Box
              className={`item ${preCertificationSelected.current}`}
              onClick={() => handleClick('precertification')}
            >
              <Typography component='span'>Pre-Certification</Typography>
            </Box>
            <Box
              className={`item ${certificationSelected.current}`}
              onClick={() => handleClick('certification')}
            >
              <Typography component='span'>Certification</Typography>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default FilterCard
