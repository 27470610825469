import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import { parseISO, format } from 'date-fns';
import ReactJson from 'react-json-view'

import {
  Box,
  Card,
  CircularProgress,
  Modal,
  Paper,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeveloperModeIcon from '@material-ui/icons/DeveloperMode';

import { getLogs } from '../../utils/api.js'

import LogsTable from 'mui-datatables';
import logsColumns from './logs-columns.js'

const useStyles = makeStyles((theme) => ({
  logList: {
    marginTop: 25,
    padding: 15,
    '& .icon': {
      '& svg': {
        fill: theme.palette.secondary.main,
      }
    },
    '& .icon:hover': {
      '& svg': {
        fill: theme.palette.primary.main,
      }
    },
    '& .token': {
      position: 'relative',
      height: 20,
      '& .container': {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        boxSizing: "border-box",
        display: "block",
        width: "100%",
        '& .text': {
          boxSizing: "border-box",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap"
        }
      }
    },
  },
  json: {
    width: 600,
    height: 600,
    marginTop: 65,
    margin: '0 auto',
    maxWidth: 600,
    padding: 25,
    overflowX: 'hidden',
    overflowY: 'scroll'
  }
}));

const tableOptions = {
  selectableRows: 'none',
  elevation: 0,
  print: false,
  viewColumns: false,
  search: true,
  filter: false,
  download: false,
  responsive: 'standard'
};

const desiredFormat = 'MM-dd-yyyy HH:mm'
const formatDateColumn = (value, rowMeta) => {
  return <Typography component="div" className="date">{format(parseISO(value), desiredFormat)}</Typography>
}

const formatRequestColumn = (value, rowMeta) => {
  const data = JSON.parse(value)
  return data.resource
}

const formatTokenColumn = (value) => {
  return (
    <Box key={`token-${value}`} className="token">
      <Box className="container">
        <Box display="flex" flexDirection="row">
          <Typography className="text">
            {value}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

const LogList = (props) => {
  const classes = useStyles();
  const params = useParams();
  const [showModal, setShowModal] = useState(false)
  const [requestData, setRequestData] = useState()
  const [inFlight, setInFlight] = useState(false)
  const [logs, setLogs] = useState()

  useEffect(() => {
    const doWork = async () => {
      try {
        const results = await getLogs(params)

        if (results) {
          setLogs(results)
          setInFlight(false)
        } else {
          setLogs([])
          setInFlight(false)
        }
      } catch (err) {
        setInFlight(false)
      }
    }

    if (inFlight === false && !logs) {
      setInFlight(true)
      doWork()
    }
  }, [inFlight, logs, params, setInFlight])

  const handleClose = () => {
    setShowModal(false)
  }

  const handleShowJson = (rowIndex) => {
    const data = logs[rowIndex]
    const request = JSON.parse(data.request)
    const response = JSON.parse(data.response)

    setRequestData({ request, response })
    setShowModal(true)
  }


  const formatAction = (value, rowMeta) => {
    return (
      <Box
        title="Show Req/Res"
        className="icon"
        onClick={() => handleShowJson(rowMeta.rowIndex)}
      >
        <DeveloperModeIcon />
      </Box>
    )
  }

  const showSpinner = inFlight === true ? <CircularProgress /> : null

  logsColumns.forEach((column) => {
    switch (column.name) {
      case '': {
        if (column.label === 'Actions') {
          column.options.customBodyRender = formatAction
        }
        break;
      }
      case 'createdAt':
      case 'revokeAt':
        column.options.customBodyRender = formatDateColumn
        break;
      case 'request':
        column.options.customBodyRender = formatRequestColumn
        break;
      case 'token':
        column.options.customBodyRender = formatTokenColumn
        break;
      default:
        break;
    }
  })

  return (
    <Card variant="outlined" className={classes.logList}>
      {showSpinner}
      <LogsTable
        title="Logs"
        data={logs}
        columns={logsColumns}
        options={tableOptions}
      />
      <Modal
        open={showModal}
        onClose={handleClose}
      >
        <Paper className={classes.json} elevation={0}>
          <ReactJson
            src={requestData}
            name="log"
            indentWidth="2"
          />
        </Paper>
      </Modal>
    </Card>
  );
}

export default LogList
