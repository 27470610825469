import featureFlags from './feature-flags.js'

const config = {
  local: {
    useSentry: false,
    baseUrl: 'https://9qnghlsvzh.execute-api.us-east-2.amazonaws.com/dev',
    appId: 'e5c2b729788276b65f2026f3bf57538c0568cda0483f1dd13337b2946cd62570',
    redirectUri: 'http://localhost:3000',
    responsesEndpoint: 'https://di2mbag1fc.execute-api.us-east-2.amazonaws.com/dev',
    tokensEndpoint: 'https://hksh4p2a7j.execute-api.us-east-2.amazonaws.com/dev'
  },
  test: {
    useSentry: false,
    baseUrl: 'https://9qnghlsvzh.execute-api.us-east-2.amazonaws.com/dev',
    appId: 'e5c2b729788276b65f2026f3bf57538c0568cda0483f1dd13337b2946cd62570',
    redirectUri: 'http://localhost:3000',
    responsesEndpoint: 'https://di2mbag1fc.execute-api.us-east-2.amazonaws.com/dev',
    tokensEndpoint: 'https://hksh4p2a7j.execute-api.us-east-2.amazonaws.com/dev'
  },
  dev: {
    useSentry: true,
    appId: 'e5c2b729788276b65f2026f3bf57538c0568cda0483f1dd13337b2946cd62570',
    redirectUri: 'https://admin.conexxus.rebartechnology.io',
    apiEndpoint: 'https://l10ozqhc03.execute-api.us-east-2.amazonaws.com/dev'
  },
  stg: {
    useSentry: true,
    appId: 'e5c2b729788276b65f2026f3bf57538c0568cda0483f1dd13337b2946cd62570',
    redirectUri: 'https://admin-stg.factory.openretailing.org',
    apiEndpoint: 'https://1qophdh08j.execute-api.us-east-2.amazonaws.com/stg'
  },
  prd: {
    useSentry: true,
    appId: 'e5c2b729788276b65f2026f3bf57538c0568cda0483f1dd13337b2946cd62570',
    redirectUri: 'https://admin.factory.openretailing.org',
    apiEndpoint: 'https://t0kkcw0lzk.execute-api.us-east-2.amazonaws.com/prd'
  }
}

// const env = REACT_APP_BUILD_ENV ? REACT_APP_BUILD_ENV : 'test';
const env = process.env.REACT_APP_BUILD_ENV ? `${process.env.REACT_APP_BUILD_ENV}` : 'dev';

export default {
  ...config[env],
  featureFlags
}
