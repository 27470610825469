import React, { useState } from 'react';
import { useHistory } from 'react-router-dom'
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ResponsesManagementContext from '../../context/responses-management-context'
import { getResponses } from '../../utils/api.js'
import ResponsesList from './responses-list.js'
import ResponseForm from '../response/form.js'

const useStyles = makeStyles((theme) => ({
  responses: {
    padding: 15,
    width: 'calc(100% - 275px)',
    '& .header': {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 25,
      '& .title': {
        flexGrow: 1
      }
    },
    '& .add-responses': {
      borderRadius: 3,
      backgroundColor: theme.palette.secondary.main,
      color: 'white',
      height: 45,
      width: 200,
      border: '1px solid #000',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer'
    },
    '& .add-responses:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'white'
    }
  }
}));
const responsesMachine = {
  'unloaded': {
    LOAD: 'loading'
  },
  'loading': {
    FAIL: 'failed',
    LOADED: 'display'
  },
  'display': {
    LOADED: 'displayed'
  },
  'displayed': {
    EDIT: 'edit'
  },
  'edit': {
    SAVE: 'save',
    CANCEL: 'displayed',
    FAIL: 'failed'
  },
  'save': {
    SAVED: 'unloaded',
    FAIL: 'failed'
  },
  'failed': {
    LOAD: 'unloaded',
    LOADED: 'displayed'
  }
}
const ResponsesPage = () => {
  const history = useHistory()
  const classes = useStyles()
  const [currentState, setCurrentState] = useState('unloaded')
  const [responses, setResponses] = useState([])
  const [error, setError] = useState()
  const [focusedResponse, setFocusedResponse] = useState()

  const loadResponses = async () => {
    try {
      const results = await getResponses()
      return results;
    } catch (err) {
      transition({ cmd: 'FAIL', err: err })
    }
  }

  const transition = (actions) => {
    let action, prevState = '';
    if (typeof actions === 'object') {
      action = actions.cmd
      prevState = actions.prevState
    } else {
      action = actions
      prevState = currentState
    }
    const nextState = responsesMachine[prevState][action]
    if (nextState) {
      command(nextState, actions)
      setCurrentState(nextState)
    }
  }

  const command = async (nextState, action) => {
    switch (nextState) {
      case 'unloaded': {
        // initiate load page here
        break
      }
      case 'loading': {
        //load responses here
        const results = await loadResponses();
        transition({ cmd: 'LOADED', prevState: 'loading', responses: results });
        break
      }
      case 'display': {
        // display data (should be done by useEffect in ResponseList)
        // once displayed, set state to displayed to allow for editing when requested
        setResponses(action.responses);
        transition({ cmd: 'LOADED', prevState: 'display' });
        break
      }
      case 'displayed': {
        //do things 
        break
      }
      case 'edit': {
        // setup row for edit
        setFocusedResponse(action.row.rowData[0])
        break
      }
      case 'save': {
        //save new response to database
        // on success refresh page
        transition('SAVED')
        break
      }
      case 'failed': {
        //display the error and ?reload page? / ?close edit row?
        // setError(action.err)
        transition('LOADED') //consider transition('displayed')
        break
      }
      default:
        setCurrentState('unloaded')
        break
    }
  }
  if (currentState === 'unloaded') {
    transition('LOAD')
  }
  return (
    <ResponsesManagementContext.Provider value={{ responses, currentState, transition, focusedResponse, error, setError }} >
      <Box className={classes.responses}>
        <Typography variant="h1" component="h3" gutterBottom>Responses</Typography>
        {(currentState === 'edit') && <ResponseForm />}
        {(currentState !== 'edit') && <Box
          className="add-responses"
          onClick={() => history.push('/response/create')}
        >
          <Typography component='span'>Add Response</Typography>

        </Box>}
        <ResponsesList />
      </Box>
    </ResponsesManagementContext.Provider>
  );
}

export default ResponsesPage
