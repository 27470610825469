export default [
  {
    name: 'id',
    label: 'ID',
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: 'createdAt',
    label: 'Created',
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: 'updatedAt',
    label: 'Updated',
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: 'actions',
    label: '',
    options: {
      filter: false,
      sort: false
    }
  }
]