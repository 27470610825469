import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";

import { Box, Card, CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DetailsIcon from '@material-ui/icons/Details';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import { useTokenContext } from '../../context/token-management-context.js'
import TokenPage from '../token/page.js'
import TokenTable from 'mui-datatables';
import tokenColumns from './token-columns.js'

const useStyles = makeStyles((theme) => ({
  tokenList: {
    marginTop: 25,
    padding: 15,
    '& .icon': {
      '& svg': {
        fill: theme.palette.secondary.main,
      }
    },
    '& .icon:hover': {
      '& svg': {
        fill: theme.palette.primary.main,
      }
    },
    '& .token': {
      position: 'relative',
      width: 120,
      height: 20,
      '& .container': {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        boxSizing: "border-box",
        display: "block",
        width: "100%",
        '& .text': {
          boxSizing: "border-box",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap"
        },
        '& svg': {
          fill: theme.palette.secondary.main,
        }
      },
      '& .container:hover': {
        '& svg': {
          fill: theme.palette.primary.main,
        }
      },
    },
    '& .token-icon:hover': {
      cursor: 'pointer'
    },
    '& .date': {
      width: 100
    }
  }
}));

const tokenTableOptions = {
  selectableRows: 'none',
  elevation: 0,
  print: false,
  viewColumns: false,
  search: true,
  filter: false,
  download: false,
  responsive: 'standard'
};

// const desiredFormat = 'MM-dd-yyyy'

const formatAccessColumn = (value, rowMeta) => {
  const apiAccess = []

  value.forEach((item, index) => {
    apiAccess.push(
      <Typography key={`apiaccess-item-${index}`}>{item}</Typography>
    )
  })

  return (
    <Box key={`apiaccess-${rowMeta.rowIndex}`} display="flex" flexDirection="column">
      {apiAccess}
    </Box>
  )
}

const formatDateColumn = (value, rowMeta) => {
  //TODO: consider date maybe empty
  let dateInfo;
  if (value === '' || value === undefined) {
    dateInfo = ''
  } else {
    const columnDate = new Date(value);
    dateInfo = `${columnDate.getMonth() + 1}-${columnDate.getDate()}-${columnDate.getFullYear()}`
  }

  return <Typography component="div" className="">{dateInfo}</Typography>
}

const formatEmailsColumn = (value, rowMeta) => {
  const emails = []

  value.forEach((item, index) => {
    emails.push(
      <Typography key={`email-item-${index}`}>{item}</Typography>
    )
  })

  return (
    <Box key={`emails-${rowMeta.rowIndex}`} display="flex" flexDirection="column">
      {emails}
    </Box>
  )
}

const formatTokenColumn = (value) => {
  const handleTokenCopy = (token) => {
    navigator.clipboard.writeText(token)
  }

  return (
    <Box key={`token-${value}`} className="token">
      <Box className="container">
        <Box display="flex" flexDirection="row" onClick={() => handleTokenCopy(value)}>
          <Typography className="text">
            {value}
          </Typography>
          <FileCopyIcon fontSize="small" />
        </Box>
      </Box>
    </Box>
  )
}

const TokenList = () => {
  const classes = useStyles();
  const history = useHistory();
  const [showSpinner, setShowSpinner] = useState(null)
  const { currentState, transition, currentFilter, filtered } = useTokenContext();
  const tokenRowContainingID = 2;

  useEffect(() => {
    setShowSpinner((currentState === 'loading') ? <CircularProgress /> : null)
  }, [currentState, setShowSpinner, filtered, currentFilter])

  const handleShowDetail = (rowIndex) => {
    history.push(`/${filtered[rowIndex].token}/details`)
  }

  const handleEditDetail = (selectedItemID) => {
    window.scrollTo(0, 0)
    transition({ cmd: 'EDIT', prevState: 'displayed', row: selectedItemID })
  }

  const formatAction = (value, rowMeta) => {
    return (
      <Box>
        <Box
          title="View Details"
          className="icon"
          onClick={() => handleShowDetail(rowMeta.rowIndex)}
        >
          <DetailsIcon />
        </Box>
        <Box
          title="Edit Details"
          className="icon"
          onClick={() => handleEditDetail(rowMeta.rowData[tokenRowContainingID])}
        >
          {currentState !== 'edit' ? <EditIcon /> : null}
        </Box>
      </Box>
    )
  }


  tokenColumns.forEach((column) => {
    switch (column.name) {
      case '': {
        if (column.label === 'Actions') {
          column.options.customBodyRender = formatAction
        }
        break;
      }
      case 'apiAccess':
        column.options.customBodyRender = formatAccessColumn
        break;
      case 'emails':
        column.options.customBodyRender = formatEmailsColumn
        break;
      case 'createdAt':
      case 'revokeAt':
        column.options.customBodyRender = formatDateColumn
        break;
      case 'token':
        column.options.customBodyRender = formatTokenColumn
        break;
      default:
        break;
    }
  })

  return (
    <Card variant="outlined" className={classes.tokenList}>
      {showSpinner}
      {currentState === 'edit' &&
        <TokenPage />
      }
      <TokenTable
        data={filtered}
        columns={tokenColumns}
        options={tokenTableOptions}
      />
    </Card>
  );
}


export default TokenList
