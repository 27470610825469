import * as React from 'react'
import { MenuItem } from '@material-ui/core';
import { getResponses } from './api.js'
export const getResponseEndPoints = async () => {
    const responses = await getResponses()
    // if (typeof responses !== 'array') {
    //     return false
    // }
    const EndPointData = responses.map((item) => {
        // console.log(item)
        // const newEP = item.id.substring(0, item.id.indexOf(':default'))
        return item.id
    })
    // console.log(EndPointData)
    const endpointFieldData = EndPointData.filter((item) => {
        if (item.indexOf(':default') === -1) {
            return false
        }
        return true
    })
    const fieldData = endpointFieldData.map((endpoint) => {
        // console.log(endpoint)
        return endpoint.substring(0, endpoint.indexOf(':default') + 1)
    })
    // console.log(fieldData)
    return fieldData
}

export const getSelectItems = async () => {
    const selectedItems = await getResponseEndPoints()
    const selectItems = selectedItems.map((item, index) => {
        return (
            <MenuItem key={index} value={item.toString()}>{item}</MenuItem>
        )
        // return `<MenuItem value="${item}">${item}</MenuItem>`
    })
    return selectItems
}