
import config from '../../config'
const { featureFlags } = config

const columns = [
  {
    name: 'name',
    label: 'Label',
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: 'tokenType',
    label: 'Type',
    options: {
      filter: false,
      sort: false
    }
  },
  {
    name: 'token',
    label: 'Token',
    options: {
      filter: false,
      sort: false
    }
  },
  {
    name: 'emails',
    label: 'Emails',
    options: {
      filter: false,
      sort: false
    }
  },
  {
    name: 'apiAccess',
    label: 'Access',
    options: {
      filter: false,
      sort: false
    }
  },
  {
    name: 'createdAt',
    label: 'Created',
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: 'revokeAt',
    label: 'Revoked',
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: '',
    label: 'Actions',
    options: {
      filter: false,
      sort: false
    }
  }
]

export default columns.filter((column) => {
  if (column.label === 'Actions') {
    return featureFlags.logs
  }
  return true
})
