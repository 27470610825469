import React from 'react';

import { useLocation, NavLink } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import config from '../../config/index.js'

const { featureFlags } = config

const useStyles = makeStyles((theme) => ({
  sidebar: {
    borderRight: '1px solid #eeeeee',
    display: 'flex',
    flexDirection: 'column',
    width: 275,
    height: '100%',
    minHeight: '100vh',
    padding: 15,
    top: 50,
    '& .link': {
      textDecoration: 'none',
      marginBottom: 15
    },
    '& .selected': {
      fontWeight: 'bold'
    }
  }
}));

const SideBar = (props) => {
  const classes = useStyles();
  const location = useLocation();

  const showResponses = featureFlags.responses === false ? null : (
    <NavLink
      to="/api-responses"
      className="link"
      activeClassName={location.pathname === '/api-responses' ? 'selected' : ''}
    >
      Responses
    </NavLink>
  )


  return (
    <Box className={classes.sidebar}>
      <NavLink to="/"
        className="link"
        activeClassName={location.pathname === '/' ? 'selected' : ''}
      >
        Manage Tokens
      </NavLink>
      {showResponses}
    </Box>
  );
}

export default SideBar
