
const featureFlags = {
  local: {
    responses: true,
    logs: true
  },
  test: {
    responses: true,
    logs: true
  },
  dev: {
    responses: true,
    logs: true
  },
  stg: {
    responses: true,
    logs: true
  },
  prd: {
    responses: true,
    logs: true
  }
};
const env = process.env.REACT_APP_BUILD_ENV ? `${process.env.REACT_APP_BUILD_ENV}` : 'test';

export default featureFlags[env];
