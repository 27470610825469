import React, { useContext } from 'react'

const TokenManagementContext = React.createContext({
    currentState: 'idle',
    tokens: [],
    error: null,
    selectedItem: null,
    currentFilter: 'all',
    filtered: [],
    transition: (actions) => { }
})

export const useTokenContext = () => useContext(TokenManagementContext)

export default TokenManagementContext