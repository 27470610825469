import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { makeStyles, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import * as Sentry from "@sentry/react";

import { Box, Container } from '@material-ui/core';

import config from './config'

import AuthenticatedRoute from './components/authenticated-route.js'
import AuthProvider from './context/auth.js'
import ErrorHandler from './error-handler.js'
import Header from './containers/header/container.js'
import ManageTokensPage from './containers/manage-tokens/page.js'
import SideBar from './containers/sidebar/sidebar.js'
import TokenPage from './containers/token/page.js'
import TokenDetailPage from './containers/token-detail/page.js'
import ResponsesPage from './containers/responses/page.js'
import ResponseForm from './containers/response/form.js'

import theme from './theme/cxm.js';
import './index.css';

const { useSentry } = config

if (useSentry === true) {
  Sentry.init({
    dsn: "https://18a00892396f4d599676a5ef5f0d99ef@o427778.ingest.sentry.io/5447829",
    tracesSampleRate: 1.0,
  });
}

const useStyles = makeStyles(() => ({
  app: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    minHeight: '100vh',
    '& .content': {
      display: 'flex',
      flexDirection: 'row',
      width: '100%'
    }
  }
}));

const App = () => {
  const classes = useStyles()

  return (
    <Container maxWidth={false} disableGutters>
      <MuiThemeProvider theme={theme}>
        <ErrorHandler>
          <Router>
            <AuthProvider>
              <Box className={classes.app}>
                <Header />
                <Box className="content">
                  <SideBar />
                  <Switch>
                    <AuthenticatedRoute
                      exact
                      path='/'
                      component={ManageTokensPage}
                    />
                    <AuthenticatedRoute
                      exact
                      path="/:token/details"
                      component={TokenDetailPage}
                    />
                    <AuthenticatedRoute
                      exact
                      path="/token/create"
                      component={TokenPage}
                    />
                    <AuthenticatedRoute
                      exact
                      path="/api-responses"
                      component={ResponsesPage}
                    />
                    <AuthenticatedRoute
                      exact
                      path="/response/create"
                      component={ResponseForm}
                    />
                  </Switch>
                </Box>
              </Box>
            </AuthProvider>
          </Router>
        </ErrorHandler>
      </MuiThemeProvider>
    </Container>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));
