/*
  ref: https://css-tricks.com/robust-react-user-interfaces-with-finite-state-machines/

  the state machine will allow the dispatch of Actions from components
  lower in the tree. These actions are constrained to be in a single
  state at any given point. Transitioning from one state to the next
  should be carefully considered when adding new ones.
*/
import _ from 'lodash';
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from "react-router-dom";
import qs from 'qs'

import AuthContext from './auth-context.js'
import { AuthFailure } from '../components/auth-failure'
import { oauthRedirect, oauthFetchToken, oauthFetchUser } from '../utils/api.js'

const AuthStateMachine = {
  'idle': {
    REQUEST: 'code',
    GET_TOKEN: 'token'
  },
  'code': {
    GET_TOKEN: 'token', 
    SUCCESS: 'authenticated'
  },
  'token': {
    SUCCESS: 'authenticated',
    ERROR: 'failed'
  },
  'authenticated': {
    GET_TOKEN: 'token', 
    GET_USER: 'user'
  },
  'user': {
    SUCCESS: 'user',
    NOT_ADMIN: 'blocked'
  },
  'blocked': {
    BLOCKED: 'blocked'
  }
}

const AuthProvider = (props) => {
  const location = useLocation()
  const history = useHistory()
  const [isAdmin, setIsAdmin] = useState(false)
  const [currentState, setCurrentState] = useState('idle')
  const [token, setToken] = useState() // eslint-disable-line no-unused-vars
  const [user, setUser] = useState()
  const [error, setError] = useState() // eslint-disable-line no-unused-vars
  const params = location.search ? qs.parse(location.search.replace('?', '')) : {}

  useEffect(() => {
    if (currentState === 'authenticated' && user) { 
      setIsAdmin(_.get(user, 'is_admin', false));
      history.push('/')
    }
  }, [currentState, history, user, setIsAdmin])

  const transition = (action) => {
    /*
      prevState exists here because a transition can be called recursively
      from a command. the necessity for the prevState is the scope of
      currentState is captured in this function on the first pass, which
      if executed recursively would do that last action.
    */
    const actionType = _.get(action, 'type');
    const prevState = _.get(action, 'prevState', currentState );
    const nextState = _.get(AuthStateMachine, [prevState, actionType]);
  
    if (nextState) {
      command(nextState, action)
      setCurrentState(nextState)
    }
  }

  const command = async (nextState, action) => {    
    switch (nextState) {
      case 'code': {
        const result = await oauthRedirect();

        transition({...result })
        break
      }
      case 'token': {
        const result = await oauthFetchToken(action.payload);

        transition({...result, prevState: nextState })
        break
      }
      case 'authenticated': {
        const { payload } = action;

        if (payload.access_token) {
          setToken(payload.access_token)
          transition({ type: 'GET_USER', prevState: nextState })
        }
        break;
      }
      case 'user': {
        const result = await oauthFetchUser();
        const { payload } = result
        setUser(payload)

        if( _.get(payload, 'is_admin', false) === false){
          transition({ type: 'NOT_ADMIN', prevState: 'user' })
        }
        break;
      }
      case 'blocked': {
        setCurrentState('blocked')
        break
      }
      default:
        setCurrentState('idle')
        break
    }
  }

  if (currentState === 'blocked') {
    return (
      <AuthFailure/>
    );
  }

  if (currentState === 'idle' && !params.code) {
    transition({ type: 'REQUEST' })
  }

  if (!token && params.code) {    
    transition({
      type: 'GET_TOKEN',
      payload: qs.parse(location.search.replace('?', ''))
    })
  }

  return (
    <AuthContext.Provider value={{ currentState, error, isAdmin, user }}>
      {props.children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
