import React from 'react';
import { Route } from 'react-router-dom';

import { useAuthContext } from '../context/auth-context.js';

const AuthenticatedRoute = (args) => {
  const authContext = useAuthContext()

  if (authContext.isAdmin === false) {
    return null
  } 

  const { component: Component, ...rest } = args;

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export default AuthenticatedRoute;
