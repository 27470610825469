import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles(() => ({
  app: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    minHeight: '100vh',
    paddingTop: '25px',
    paddingLeft: '25px',
    '& .content': {
      display: 'flex',
      flexDirection: 'row',
      width: '100%'
    }
  }
}));

export const AuthFailure = () => {
  const classes = useStyles()

  return (
    <Box className={classes.app}>
      <Box className="header">
        <Typography variant="h1" component="h3" gutterBottom className="title">403 Forbidden</Typography>
      </Box>
      <Box className="content">
        <Typography component='span'>You do not have the required permissions to access this page.</Typography>
      </Box>
    </Box>
  )
};

export default AuthFailure;
