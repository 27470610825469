import React, { useContext } from 'react'

const ProductContext = React.createContext({
  currentState: 'idle',
  user: null,
  error: null
})

export const useAuthContext = () => useContext(ProductContext)

export default ProductContext
