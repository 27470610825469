import React, { useState } from 'react';
import { useHistory } from "react-router-dom";

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import FilterCard from './filter-card.js'
import TokenList from './token-list.js'
import { getTokens } from '../../utils/api.js'

import TokenManagementContext from '../../context/token-management-context'

const useStyles = makeStyles((theme) => ({
  manageTokens: {
    padding: 15,
    width: 'calc(100% - 275px)',
    '& .header': {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 25,
      '& .title': {
        flexGrow: 1
      }
    },
    '& .add-token': {
      borderRadius: 3,
      backgroundColor: theme.palette.secondary.main,
      color: 'white',
      height: 45,
      width: 200,
      border: '1px solid #000',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer'
    },
    '& .add-token:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'white'
    }
  }
}));

const tokensMachine = {
  'unloaded': {
    LOAD: 'loading'
  },
  'loading': {
    FAIL: 'failed',
    LOADED: 'display'
  },
  'display': {
    DISPLAYED: 'displayed'
  },
  'displayed': {
    EDIT: 'edit',
    FILTER: 'filter'
  },
  'filter': {
    FILTERED: 'displayed'
  },
  'edit': {
    SAVE: 'save',
    CANCEL: 'displayed',
    FAIL: 'failed'
  },
  'save': {
    SAVED: 'unloaded',
    FAIL: 'failed'
  },
  'failed': {
    LOAD: 'unloaded',
    LOADED: 'displayed'
  }
}
const ManageTokensPage = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [currentFilter, setCurrentFilter] = useState('all');
  const [currentState, setCurrentState] = useState('unloaded');
  const [filtered, setFiltered] = useState([])
  const [selectedItem, setSelectedItem] = useState();
  const [tokens, setTokens] = useState([])
  const [error, setError] = useState();

  const configureNewFilter = (filterChoice) => {
    let myFilteredList;
    if (filterChoice !== 'all') {
      myFilteredList = tokens.filter(item => item.tokenType === filterChoice.toUpperCase())

    } else {
      myFilteredList = tokens;
    }
    return myFilteredList;
  }

  const loadTokens = async () => {
    try {
      const results = await getTokens()
      return results;
    } catch (err) {
      transition({ cmd: 'FAIL', err: err });
    }
  }
  const transition = (actions) => {
    let action, prevState = '';
    if (typeof actions === 'object') {
      action = actions.cmd
      prevState = actions.prevState
    } else {
      action = actions
      prevState = currentState
    }
    const nextState = tokensMachine[prevState][action]
    if (nextState) {
      command(nextState, actions)
      setCurrentState(nextState)
    }
  }

  const command = async (nextState, action) => {
    switch (nextState) {
      case 'unloaded': {
        // initiate load page here
        break
      }
      case 'loading': {
        const results = await loadTokens();
        transition({ cmd: 'LOADED', prevState: 'loading', tokens: results });
        break
      }
      case 'display': {
        setTokens(action.tokens)
        let myFilteredList = []
        if (currentFilter !== 'all') {
          myFilteredList = action.tokens.filter(item => item.tokenType === currentFilter)

        } else {
          myFilteredList = action.tokens
        }
        setFiltered(myFilteredList);
        transition({ cmd: 'DISPLAYED', prevState: 'display' })
        // const newFiltered = tokens.filter(token => token.tokenType === currentFilter)
        break
      }
      case 'displayed': {
        //do things 
        break
      }
      case 'filter': {
        setCurrentFilter(action.filter);
        const myFilteredList = configureNewFilter(action.filter);
        setFiltered(myFilteredList);
        transition({ cmd: 'FILTERED', prevState: 'filter' });
        break
      }
      case 'edit': {
        // setup row for edit
        setSelectedItem(action.row)
        break
      }
      case 'save': {
        // on success refresh page
        transition('SAVED')
        break
      }
      case 'failed': {
        //display the error and ?reload page? / ?close edit row?
        setError(action.err)
        transition('LOADED') //consider transition('displayed')
        break
      }
      default:
        setCurrentState('unloaded')
        break
    }
  }
  if (currentState === 'unloaded') {
    transition('LOAD')
  }

  return (
    <TokenManagementContext.Provider value={{ tokens, currentState, currentFilter, filtered, transition, selectedItem, error }} >
      <Box className={classes.manageTokens}>
        <Box className="header">
          <Typography variant="h1" component="h3" gutterBottom className="title">Tokens</Typography>
          {(currentState !== 'edit') &&
            <Box
              className="add-token"
              onClick={() => history.push('/token/create')}
            >
              <Typography component='span'>Add Token</Typography>
            </Box>
          }
        </Box>
        <Box>
          <FilterCard />
          <TokenList />
        </Box>
      </Box>
    </TokenManagementContext.Provider>
  );
}


export default ManageTokensPage