import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import TokenForm from './form.js'

import { useTokenContext } from '../../context/token-management-context'

const useStyles = makeStyles((theme) => ({
  logs: {
    padding: 15,
    width: '100%',
    '& .link': {
      cursor: 'pointer'
    }
  }
}));

const TokenPage = (props) => {
  const classes = useStyles();
  const { currentState } = useTokenContext();

  return (
    <Box className={classes.logs}>
      <Typography variant="h1" component="h3" gutterBottom>{currentState === 'edit' ? 'Edit' : 'Create'} a Token</Typography>
      <TokenForm />
    </Box>
  );
}

export default TokenPage
