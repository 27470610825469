import React, { useEffect, useState } from 'react'
import { parseISO, format } from 'date-fns';

import { Card, CircularProgress, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useResponsesContext } from '../../context/responses-management-context'
import ResponsesTable from 'mui-datatables';
import responsesColumns from './responses-columns.js'

const useStyles = makeStyles((theme) => ({
  list: {
    marginTop: 25,
    padding: 15,
    '& .token': {
      marginRight: 15,
      flexGrow: 1
    },
    '& .token-icon:hover': {
      cursor: 'pointer'
    },
    '& .date': {
      width: 100
    }
  }
}));

const tableOptions = {
  selectableRows: 'none',
  elevation: 0,
  print: false,
  viewColumns: false,
  search: true,
  filter: false,
  download: false,
  responsive: 'standard'
};

const desiredFormat = 'MM-dd-yyyy'
const formatDateColumn = (value, rowMeta) => {
  if (!value) return null

  return <Typography component="div" className="date">{format(parseISO(value), desiredFormat)}</Typography>
}

const ResponsesList = (props) => {
  const classes = useStyles();
  const [showSpinner, setShowSpinner] = useState(null)
  const [disableButton, setDisableButton] = useState(null)
  let { responses, currentState, focusedResponse, transition } = useResponsesContext()

  useEffect(() => {
    setShowSpinner((currentState === 'loading') ? <CircularProgress /> : null)
    setDisableButton((currentState === "edit") ? 'disabled' : null)
  }, [currentState, setShowSpinner, focusedResponse, responses])

  const formatActionsColumn = (value, rowMeta) => {
    // if (!rowMeta.rowData[0].toString().endsWith(':default')) {}
    if (currentState === 'edit' && rowMeta.rowData[0].toString() !== focusedResponse) {
      return null;
    }
    return (
      <Typography component="div" className="action-col">
        <Button
          variant='contained'
          color='primary'
          type='submit'
          className='submit-button'
          disabled={disableButton}
          onClick={() => { transition({ cmd: 'EDIT', prevState: 'displayed', row: rowMeta }) }}
        >
          Edit
      </Button>
      </Typography>
    )
  }
  responsesColumns.forEach((column) => {
    switch (column.name) {
      case 'createdAt':
      case 'updatedAt':
        column.options.customBodyRender = formatDateColumn
        break;
      case 'actions':
        column.options.customBodyRender = formatActionsColumn
        break
      default:
        break;
    }
  })

  return (
    < Card variant="outlined" className={classes.list} >
      {showSpinner}
      < ResponsesTable
        data={responses}
        columns={responsesColumns}
        options={tableOptions}
      />
    </Card >
  )

}

export default ResponsesList