import React from 'react';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import logo from '../../icons/cx-logo.jpg'

const useStyles = makeStyles((theme) => ({
  header: {
    borderBottom: '1px solid #eeeeee',
    display: 'flex',
    height: 50,
    padding: 15,
    paddingBottom: 0,
    '& .title': {
      transform: 'translate(10px, 3px)'
    }
  }
}));

const Header = () => {
  const classes = useStyles();

  return (
    <Box className={classes.header}>
      <img height="40" src={logo} alt="Conexxus Logo" />
      <Typography className="title">
        Open Retailing API Factory Admin Portal
      </Typography>
    </Box>
  );
}

export default Header
