import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { add, format } from 'date-fns';
import { Formik, FieldArray } from 'formik';
import { useHistory } from "react-router-dom";
import {
  TextField,
  Button,
  Box,
  MenuItem,
  FormControl,
  FormControlLabel,
  Select,
  InputLabel,
  Checkbox,
  FormHelperText,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { createToken, getToken, updateToken } from '../../utils/api.js'
import { useTokenContext } from '../../context/token-management-context.js'


import validationSchema from './validation-schema';
import apiValues from './api-access.js'

const useStyles = makeStyles((theme) => ({
  form: {},
  label: {
    background: 'white'
  }
}))

const desiredFormat = 'yyyy-MM-dd'

const getApiCheckboxes = (helpers, values) => {
  // helpers is a formik FieldArray component
  // values are the actual values from formik
  const handleChange = (args) => {
    const { currentTarget } = args

    if (currentTarget.checked === true) {
      helpers.push(currentTarget.value)
    } else {
      helpers.remove(currentTarget.value)
    }
  }

  const boxes = apiValues.map((item, index) => {
    const checked = values.findIndex(previouslyChecked => previouslyChecked === item.value) > -1
    return (
      <FormControlLabel
        control={
          <Checkbox
            value={item.value}
            disabled={item.disabled}
            checked={checked}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        }
        label={item.name}
        key={index}
      />
    )
  })

  return boxes
}

const TokenForm = () => {
  const classes = useStyles();
  const history = useHistory();
  const [disableButton, setDisableButton] = useState(null);
  const [inFlight, setInFlight] = useState(false);
  const [tokenData, setTokenData] = useState({});
  const { currentState, transition, selectedItem } = useTokenContext();
  // const tokenContext = useTokenContext()

  const [initialFormValues, setInitialFormValues] = useState({
    name: '',
    tokenType: '',
    revokedAt: format(add(new Date(), { days: 30 }), desiredFormat),
    apiAccess: [],
    emails: []
  });
  useEffect(() => {
    const doWork = async () => {
      try {
        const tokenDetail = await getToken({ token: selectedItem });
        setTokenData(tokenDetail)
        const initFormVals = {
          name: tokenDetail.name,
          tokenType: tokenDetail.tokenType,
          revokedAt: format(add(new Date(), { days: 29 }), desiredFormat),
          apiAccess: tokenDetail.apiAccess,
          emails: tokenDetail.emails
        }
        setInitialFormValues(initFormVals);
        setInFlight(false)
      } catch (err) {
        setInFlight(false)
      }
    }
    if (inFlight === false && currentState === 'edit' && Object.keys(tokenData).length === 0) {
      setInFlight(true)
      doWork()
    }

  }, [currentState, transition, inFlight, setInFlight, tokenData, setTokenData, selectedItem, setInitialFormValues])

  const handleSave = async (data) => {
    try {
      if (currentState !== 'edit') {
        const withoutSpaces = data.emails.replaceAll(' ', ',')
        const allEmails = [].concat(withoutSpaces.split(','))
        const emails = [] // unique

        allEmails.forEach((email) => {
          if (email) {
            const _email = email.trim()
            if (!emails.includes(_email)) {
              emails.push(_email)
            }
          }
        })

        data.emails = emails
      }
      const newRevokeAt = new Date(data.revokedAt);
      data.revokeAt = newRevokeAt.getTime();
      setDisableButton('');
      if (currentState === 'edit') {
        await updateToken(selectedItem, data);
      } else {
        await createToken(data);
      }
      setDisableButton('')
      transition({ cmd: 'SAVED', prevState: 'save' });
      history.push('/')
    } catch (err) {
      setDisableButton('')
    }
  }

  if (currentState === 'edit' && Object.keys(tokenData).length === 0) {
    return null;
  }

  if (currentState === 'edit' && Object.keys(tokenData).length > 0 && initialFormValues.name === "") {
    return null;
  }


  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={initialFormValues}
      onSubmit={handleSave}
      validationSchema={validationSchema}
    >
      {(props) => {
        const {
          errors,
          handleChange,
          handleSubmit,
          values
        } = props

        return (
          <form onSubmit={handleSubmit} className={classes.form}>
            <Box display='flex' flexDirection='column' width='50%'>
              <Box display="flex" flexDirection='column' width="100%" mb="1em">
                <TextField
                  label='Label'
                  onChange={handleChange}
                  value={values.name}
                  name='name'
                  type='text'
                  variant='outlined'
                  margin='dense'
                />
                {errors.label && (
                  <FormHelperText error={true}>{errors.label}</FormHelperText>
                )}
              </Box>
              <Box display="flex" flexDirection='column' width="100%" mb="1em">
                <FormControl
                  margin='dense'
                  variant='outlined'
                >
                  <InputLabel id='type-label' className={classes.label}>
                    Token Type
                </InputLabel>
                  <Select
                    labelId='type-label'
                    onChange={handleChange}
                    value={values.tokenType || ''}
                    name='tokenType'
                  >
                    <MenuItem value=''>None</MenuItem>
                    <MenuItem value='INTEGRATION'>Integration</MenuItem>
                    <MenuItem value='PRECERTIFICATION'>Pre-Certification</MenuItem>
                    <MenuItem value='CERTIFICATION'>Certification</MenuItem>
                  </Select>
                </FormControl>
                {errors.tokenType && (
                  <FormHelperText error={true}>{errors.tokenType}</FormHelperText>
                )}
              </Box>
              <Box display='flex' flexDirection="column" mb="1em">
                <Typography>API Access:</Typography>
                <FieldArray
                  name="apiAccess"
                  render={(arrayHelpers) => getApiCheckboxes(arrayHelpers, values.apiAccess)}
                />
                {errors.apiAccess && (
                  <FormHelperText error={true}>{errors.apiAccess}</FormHelperText>
                )}
              </Box>
              <Box display='flex' flexDirection="column" mb="1em">
                <Typography>Emails:</Typography>
                <TextField
                  name='emails'
                  label='Add an email'
                  onChange={handleChange}
                  value={values.emails}
                  type='text'
                  variant='outlined'
                  margin='dense'
                />
                {errors.emails && (
                  <FormHelperText error={true}>{errors.emails}</FormHelperText>
                )}
              </Box>
              <Box mb="1em">
                <TextField
                  id="date"
                  label="Revoke At"
                  name="revokedAt"
                  type="date"
                  value={values.revokedAt}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
              <Box display='flex'>
                <Button
                  variant='contained'
                  color='primary'
                  type='submit'
                  disabled={currentState === 'edit' ? disableButton : ''}
                  className='submit-button'
                >
                  Save
              </Button>
                <Button
                  variant='outlined'
                  color='primary'
                  onClick={() => {
                    if (currentState === 'edit') {
                      transition('CANCEL')
                    } else {
                      history.push('/');

                    }
                  }
                  }
                  className='cancel-button'
                >
                  Cancel
              </Button>
              </Box>
            </Box>
          </form>
        )
      }}
    </Formik>
  )
}

TokenForm.propTypes = {
  errors: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.array.isRequired
}

export default TokenForm